<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
      <v-layout row wrap>
        <template>
          <v-flex
            sm=12
            xs=12
            md=12
            lg=12
          >
            <v-card>
              <v-card-title>
                  <v-row>
                    <v-col cols=12 md="3" lg="2" xl="2">
                      <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              large
                              color="deep-orange darken-1"
                              dark
                              v-bind="attrs"
                              v-on="on"
                          >
                              Manager
                          </v-btn>
                          </template>
                          <v-list>
                          <v-subheader>Manage Allowed Apps</v-subheader>
                            <v-list-item>
                              <v-list-item-title>
                                <v-btn
                                  color="success"
                                  dark
                                  @click="doManager()"
                                >
                                  New App
                                  <v-icon
                                    dark
                                    right
                                  >
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </v-list-item-title>
                            </v-list-item>
														<v-list-item>
															<v-list-item-title>
																<v-btn
																	color="blue"
																	dark
																	@click="exportTxt()"
																>
																	Export To TXT
																	<v-icon
																		dark
																		right
																	>
																		mdi-cloud-download
																	</v-icon>
																</v-btn>
															</v-list-item-title>
														</v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                  <v-btn
                                    color="error"
                                    dark
                                    @click="doDelete(true)"
                                  >
                                    Delete All
                                    <v-icon
                                      dark
                                      right
                                    >
                                      mdi-close-octagon
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                          </v-list>
                      </v-menu>
                    </v-col>
										<v-col cols="auto">
											<v-file-input
												v-model="chosenFile"
												show-size
												counter
												accept="text/plain"
												label="Import from TXT"
												@change="doImportTXT"
											/>
										</v-col>
                    <v-col cols=12 md="6" lg="4" xl="4">
                      <v-text-field
                        v-model="search"
                        prepend-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
              </v-card-title>   
                    <v-data-table
                      :headers="headers"
                      :items="items"
                      :search="search"
                      :footer-props="{'items-per-page-options':[15,30,50,100]}"
                      :items-per-page="15"
                    >
                      <template v-slot:[`item.Lib`]="{ item }">
                        <v-btn
                          white--text
                          text
                          small
                        >
                        {{item.Lib}}
                        </v-btn> 
                      </template>
                      <template v-slot:[`item.Address`]="{ item }">
                        <v-btn
                          white--text
                          text
                          small
                          v-clipboard="item.Address"
                        >
                        {{item.Address}}
                        </v-btn> 
                      </template>
                      <template v-slot:[`item.appName`]="{ item }">
                        <v-textarea
                          class="mx-2"
                          label="App Name"
                          rows="2"
                          :value="item.appName"
                        ></v-textarea>
                      </template>
                      <template v-slot:[`item.app_id`]="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="deep-orange darken-1"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              small
                            >
                              Manager
                            <v-icon
                              light
                              right
                            >
                              mdi-pencil
                            </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title>
                                <v-btn value="justify" color="warning" text small @click="doDelete(item.app_id)">
                                  <v-icon
                                    light
                                    left
                                  >
                                    mdi-delete
                                  </v-icon>
                                  Delete
                                </v-btn>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                  </v-data-table>           
            </v-card>
          </v-flex>
        </template>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
				<v-dialog
					v-model="boxImport"
					transition="dialog-bottom-transition"
					max-width="600"
					dark
				>
					<v-card>
						<v-card-title>
							<span class="headline">Import Data Review</span>
						</v-card-title>
						<v-card-text>
							<v-simple-table>
								<template v-slot:default>
									<thead>
									<tr>
										<th class="text-left">
											Item
										</th>
										<th class="text-center">
											Type
										</th>
									</tr>
									</thead>
									<tbody>
									<tr
										v-for="item in importData"
										:key="item"
									>
										<td class="text-left">{{ item.app }}</td>
										<td class="text-center">{{ item.type }}</td>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
						<v-card-actions>
							<v-btn
								color="blue darken-1"
								@click="doImportData"
							>
								Do Import
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="doCancelImport()"
							>
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
          dark
        >
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
        <v-form ref="form" @submit.prevent="doProcess">
        <v-card>
        <v-toolbar
            color="primary"
            dark
        >MANAGER</v-toolbar>
        <v-card-text>
            <div class="text-h2 pa-12">
            <validation-provider
                v-slot="{ errors }"
                name="App Name"
                rules="required|min:1"
              >
              <v-textarea
                outlined
                label="App Name"
                v-model="appData.appName"
                 :error-messages="errors"
              ></v-textarea>
              </validation-provider>
							<v-text-field
								outlined
								label="Type"
								v-model="appData.appType"
							/>
            <v-btn-toggle borderless>
              <v-btn value="left" color="primary" type="submit" :disabled="invalid">
                  <span class="hidden-sm-and-down">Create</span>
                  <v-icon right>
                  mdi-plus
                  </v-icon>
              </v-btn>
            </v-btn-toggle>
            <div v-if="errorSubmit">
              <br />
              <v-alert
                text
                prominent
                type="error"
                icon="mdi-cloud-alert"
                timeout="5000"
              >
                {{ errorSubmit }}
              </v-alert>
            </div>
            </div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
            text
            @click="dialog = false"
            >Close</v-btn>
        </v-card-actions>
        </v-card>
        </v-form>
          </validation-observer>
        </v-dialog>
      </v-layout>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import { required, digits, min, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
  extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
  })
  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })
  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })
export default {
  name: 'Apps',
  directives: { clipboard },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
      ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
		doCancelImport(){
			this.boxImport = false;
			this.chosenFile = null;
			this.importData = [];
		},
		doImportTXT(){
			this.importData = [];
			if (!this.chosenFile) return;
			const reader = new FileReader();
			reader.readAsText(this.chosenFile);
			reader.onload = () => {
				const data = reader.result.split('\n');
				if(Array.isArray(data)){
					data.forEach(item => {
							if(item.length > 0){
								const new_data = item.split(',');
								const import_data = {
									app: new_data[0],
									type: new_data[1]
								}
								if(!this.importData.includes(import_data)) this.importData.push(import_data);
							}
						});
					this.boxImport = true;
				}else{
					this.chosenFile = null;
				}
			}
		},
		exportTxt(){
			let content = "";
			for (let i = 0; i < this.items.length; i++) {
				const type = this.items[i]['appType'] ? `,${this.items[i]['appType']}` : '';
				content += `${this.items[i]['appName']}${type}\n`;
			}
			const uri = "data:application/octet-stream," + encodeURIComponent(content);
			const link = document.createElement('a');
			link.setAttribute("download", "allowed_items.txt");
			link.setAttribute("href", uri);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(document.body.lastChild);
		},
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Apps/get`,request).then((json)=>{
          this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doManager: function(){
      this.errorSubmit = "";
      this.dialog = true;
      this.appData = {};
    },
		doImportData(){
			this.Overlay = true;
			fetchData(`${this.$API}/api/Apps/add`,{import:this.importData}).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.success(message);
					this.fetchData();
					this.boxImport = false;
					this.chosenFile = null;
					this.importData = [];
				}else{
					this.error(message);
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error('SERVER ERROR !!!');
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
    doProcess: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Apps/add`,this.appData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
                this.fetchData();
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.dialog = false;
      });
    },
    doDelete: function(Id){
      this.Overlay = true;
      this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Apps/delete`,{ app_id:Id },'DELETE').then((json)=>{
              let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.dialog = false;
          });
        }
      });
    },
  },
  data : () => ({
    secret:"",
    typeCheat: "",
    appData: {},
    Overlay: false,
    dialog: false,
    search: '',
    errorSubmit: "",
    invalid: true,
    headers: [
            { text: 'App', align: 'center', sortable: true,value: 'appName',width:"70%"},
						{ text: 'Type', align: 'center', sortable: true,value: 'appType',width:"20%"},
            { text: 'Manage', align: 'center', sortable: false,value: 'app_id',width: "10%" },
        ],
    items: [],
		importData: [],
		chosenFile: null,
		boxImport: false,
  }),
  watch:{
    // secret: function(newSecret){
    //   this.fetchSecret({secret:newSecret});
    // }
  },
  mounted(){
    this.fetchData();
  }
}
</script>
<style>
</style>