import { authService } from '../../services';
import router from '../../router'
const __API__ = localStorage.getItem('Token');
const state = __API__
    ? { status: { loggedIn: true, token:__API__} }
    : { status: {} };
    const actions = {
        login({ dispatch, commit }, { username, password, googleAuth }) {       
            authService.login(username, password, googleAuth)
                .then(
                    token => {
                        localStorage.setItem('Token', token);
                        commit('loginSuccess', token);
                        router.push('/');
                        setTimeout(() => {
                            // hiển thị message thành công sau redirect sang trang 
                            dispatch('alert/success', 'Login successful !!!', { root: true });
                        })
                    },
                    error => {
                        commit('loginFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        logout({ commit }) {
            authService.logout();
            commit('logout');
            router.push('/login');
        },
    };
    
    const mutations = {
        loginSuccess(state, token) {
            state.status = { loggedIn: true,token:token };
        },
        loginFailure(state,error) {
            state.status = {error:error};
        },
        logout(state) {
            state.status = {};
        },
    };
    
    export const auth = {
        namespaced: true,
        state,
        actions,
        mutations
    };