var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.alert.message)?_c('v-alert',{attrs:{"dense":"","type":_vm.alert.type,"timeout":"5000"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]):_vm._e(),_c('v-layout',{attrs:{"row":"","wrap":""}},[[_c('v-flex',{attrs:{"sm":"12","xs":"12","md":"12","lg":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","lg":"2","xl":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","color":"deep-orange darken-1","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Manager ")])]}}])},[_c('v-list',[_c('v-subheader',[_vm._v("Manage Allowed Apps")]),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.doManager()}}},[_vm._v(" New App "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.exportTxt()}}},[_vm._v(" Export To TXT "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-cloud-download ")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.doDelete(true)}}},[_vm._v(" Delete All "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-close-octagon ")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-file-input',{attrs:{"show-size":"","counter":"","accept":"text/plain","label":"Import from TXT"},on:{"change":_vm.doImportTXT},model:{value:(_vm.chosenFile),callback:function ($$v) {_vm.chosenFile=$$v},expression:"chosenFile"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"footer-props":{'items-per-page-options':[15,30,50,100]},"items-per-page":15},scopedSlots:_vm._u([{key:"item.Lib",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"white--text":"","text":"","small":""}},[_vm._v(" "+_vm._s(item.Lib)+" ")])]}},{key:"item.Address",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(item.Address),expression:"item.Address"}],attrs:{"white--text":"","text":"","small":""}},[_vm._v(" "+_vm._s(item.Address)+" ")])]}},{key:"item.appName",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{staticClass:"mx-2",attrs:{"label":"App Name","rows":"2","value":item.appName}})]}},{key:"item.app_id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"deep-orange darken-1","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Manager "),_c('v-icon',{attrs:{"light":"","right":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"value":"justify","color":"warning","text":"","small":""},on:{"click":function($event){return _vm.doDelete(item.app_id)}}},[_c('v-icon',{attrs:{"light":"","left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)],1)],1)],1)],1)]}}],null,true)})],1)],1)],_c('v-overlay',{attrs:{"value":_vm.Overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600","dark":""},model:{value:(_vm.boxImport),callback:function ($$v) {_vm.boxImport=$$v},expression:"boxImport"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Import Data Review")])]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Item ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Type ")])])]),_c('tbody',_vm._l((_vm.importData),function(item){return _c('tr',{key:item},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.app))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.type))])])}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":_vm.doImportData}},[_vm._v(" Do Import ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.doCancelImport()}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600","dark":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.doProcess.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("MANAGER")]),_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_c('validation-provider',{attrs:{"name":"App Name","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"App Name","error-messages":errors},model:{value:(_vm.appData.appName),callback:function ($$v) {_vm.$set(_vm.appData, "appName", $$v)},expression:"appData.appName"}})]}}],null,true)}),_c('v-text-field',{attrs:{"outlined":"","label":"Type"},model:{value:(_vm.appData.appType),callback:function ($$v) {_vm.$set(_vm.appData, "appType", $$v)},expression:"appData.appType"}}),_c('v-btn-toggle',{attrs:{"borderless":""}},[_c('v-btn',{attrs:{"value":"left","color":"primary","type":"submit","disabled":invalid}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Create")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)],1),(_vm.errorSubmit)?_c('div',[_c('br'),_c('v-alert',{attrs:{"text":"","prominent":"","type":"error","icon":"mdi-cloud-alert","timeout":"5000"}},[_vm._v(" "+_vm._s(_vm.errorSubmit)+" ")])],1):_vm._e()],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1)]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }